module.exports = [{
      plugin: require('../node_modules/@hoangmnsd/gatsby-theme-amplify-cognito/gatsby-browser.js'),
      options: {"plugins":[],"region":"eu-west-1","userPoolId":"eu-west-1_bGtX9FmMa","userPoolWebClientId":"50d9an89fjn4h9nscg35hk7nn2","doAuthenticate":["/sign-up","/account"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Pipeliner","short_name":"Pipeliner","start_url":"/","background_color":"#282433","theme_color":"#282433","display":"browser","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e24df570d303fe91f3d58929d5fd54e6"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
